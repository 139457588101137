import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    alias: ["/liveartx100"],
    component: () => import("../components/home.vue"),
  },
  {
    path: "/artists",
    alias: ["/liveartx100/artists"],
    component: () => import("../components/artists.vue"),
  },
  {
    path: "/nft_works",
    alias: ["/liveartx100/nft_works", "/artworks"],
    component: () => import("../components/nftWorks.vue"),
  },
  {
    path: "/people_vote_winner",
    component: () => import("../components/peopleVoteWinner.vue"),
  },
  {
    path: "/juror_panel_winner",
    component: () => import("../components/jurorPanelWinner.vue"),
  },
  {
    path: "/winner",
    component: () => import("../components/winningArtists.vue"),
  },
  {
    path: "/faq",
    alias: ["/liveartx100/faq"],
    component: () => import("../components/faq.vue"),
  },
  {
    path: "/terms_and_conditions",
    alias: ["/terms_and_conditions/faq"],
    component: () => import("../components/terms.vue"),
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
