import { reactive, watch, watchEffect } from "vue";
import connectWallet from "./connectWalletConnect";
import autoConnect from "./autoConnect";
import disconnectWallet from "./disconnectWallet";

const defaultState = {
  isInjected: false,
  address: "",
  chainId: "",
  status: false,
  choice: 0,
};

const web3 = {
    provider: "",
}

const web3Ins = reactive(web3);

const STATE_NAME = "userState";

const getDefaultState = () => {
  if (localStorage.getItem(STATE_NAME) !== null) {
    return JSON.parse(localStorage.getItem(STATE_NAME));
  }
  return defaultState;
};

const state = reactive(getDefaultState());
const actions = {
  connectWallet,
  autoConnect,
  disconnectWallet,
};
watch(
  () => state,
  () => {
    localStorage.setItem(STATE_NAME, JSON.stringify(state));
  },
  { deep: true }
);

watchEffect(
    () => web3Ins,
    {}
)

export default () => {
  if (localStorage.getItem(STATE_NAME) === null) {
    localStorage.setItem(STATE_NAME, JSON.stringify(state));
  }
  return {
    web3Ins,
    state,
    ...actions,
  };
};
