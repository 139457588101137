import { Web3Provider } from "@ethersproject/providers";
import connect from "./index";
import WalletConnectProvider from "@walletconnect/web3-provider";

const connectWallet = async (connector = "injected") => {
  if (connector == "injected") {
    try {
      const { state, web3Ins } = connect();
      const provider = new Web3Provider(window.ethereum);
      let accounts = await provider.listAccounts();
      if (accounts.length === 0) {
        await provider.send("eth_requestAccounts");
      }
      await provider.getNetwork();
      accounts = await provider.listAccounts();
      const address = accounts[0];
      web3Ins.provider = provider;
      state.isInjected = true;
      state.status = true;
      state.address = address;
      state.chainId = await provider.send("eth_chainId");

      window.ethereum.on('accountsChanged', async (accounts) => {
        await provider.send("eth_requestAccounts");
        await provider.getNetwork();
        accounts = await provider.listAccounts();
        web3Ins.provider = provider;
        if (accounts.length > 0) {
          state.address = accounts[0];
          state.chainId = await provider.send("eth_chainId");
        }
      })

      window.ethereum.on("disconnect", (error) => {
        console.log(error);
        console.log("disconnected");
        state.isInjected = false;
        state.status = false;
        state.address = "";
        localStorage.removeItem("userState");
      });
    } catch (err) {
      console.error(err);
    }
  } else {
    await connectWalletConnect();
  }
};

const connectWalletConnect = async () => {
  try {
    const { state, web3Ins } = connect();
    const walletConnectProvider = new WalletConnectProvider({
      infuraId: "e8d20976a34a4b599ce82c8e3d1ce4d3",
    });
    await walletConnectProvider.enable();

    const web3Provider = new Web3Provider(walletConnectProvider);
    web3Ins.provider = web3Provider;
    const signer = await web3Provider.getSigner();
    const address = await signer.getAddress();
    state.isInjected = false;
    state.status = true;
    state.address = address;
    state.chainId = await walletConnectProvider.request({
      method: "eth_chainId",
    });
    walletConnectProvider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      console.log("disconnected");
      state.isInjected = false;
      state.status = false;
      state.address = "";
      localStorage.removeItem("userState");
      localStorage.removeItem('walletconnect');
    });

    walletConnectProvider.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        state.address = accounts[0];
      }
    });

    walletConnectProvider.on("chainChanged", (chainId) => {
      state.chainId = chainId;
    });
  } catch (error) {
    console.log(error);
  }
};
export default connectWallet;
