<template>
    <div class="footer">
        <div class="footer-wrapper">
            <p class="slogan">Authentic. Verified. Curated.</p>
            <div class="w-full grid grid-cols-2 gap-8 mb-12 md:grid-cols-4 md:w-auto max-w-[700px]">
                <div class="footer-group">
                    <p class="fg-title">LiveArtX</p>
                    <ul class="fg-items">
                        <li>
                            <a href="https://liveart.xyz/drop/lovebeing">Marketplace</a>
                        </li>
                        <li>
                            <a href="https://memberships.liveart.xyz/">Memberships</a>
                        </li>
                    </ul>
                </div>
                <div class="footer-group">
                    <p class="fg-title">Resources</p>
                    <ul class="fg-items">
                        <li>
                            <a href="https://docs.liveartx.xyz/liveartx/liveartx/manifesto">User guides</a>
                        </li>
                        <li><a href="https://liveartx.medium.com/">Blog</a></li>
                        <li>
                            <a href="https://docs.liveartx.xyz/liveartx/liveartx/manifesto">White paper</a>
                        </li>
                        <li><a href="https://liveart.xyz/##">Contact us</a></li>
                    </ul>
                </div>
                <div class="footer-group">
                    <p class="fg-title">Terms</p>
                    <ul class="fg-items">
                        <li><router-link to="/terms_and_conditions">Terms of use</router-link></li>
                        <li><a href="##">Privacy policy</a></li>
                        <li><a href="##">Cookie policy</a></li>
                        <li><a href="##">Conditions of sale</a></li>
                    </ul>
                </div>
                <div class="footer-group">
                    <p class="fg-title">For Galleries</p>
                    <ul class="fg-items">
                        <li><a href="https://liveart.xyz/##">Business Services</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <ul class="social-media-links">
            <li>
                <a href="https://discord.com/invite/liveartx" target="_blank" rel="noopener"><img
                        src="~@/assets/imgs/discord_white.svg" alt="" /></a>
            </li>
            <li>
                <a href="https://twitter.com/liveartX" target="_blank" rel="noopener"><img
                        src="~@/assets/imgs/twitter_white.svg" alt="" /></a>
            </li>
            <li>
                <a href="https://t.me/LiveArtx" target="_blank" rel="noopener"><img src="~@/assets/imgs/telegram.svg"
                        alt="" /></a>
            </li>
            <li>
                <a href="https://instagram.com/liveart.xyz/" target="_blank" rel="noopener"><img
                        src="~@/assets/imgs/instagram.svg" alt="" /></a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'HomeFooter'
}
</script>

<style scoped>
p {
    margin: 0;
}

.footer {
    width: 100%;
    background: #000000;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.footer-wrapper {
    margin-top: 80px;
    max-width: 1148px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
}


.slogan {
    max-width: 448px;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: 0.374px;
    font-feature-settings: "kern" off;
    color: #ffffff;
}

.footer-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fg-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    font-feature-settings: "kern" off;
    color: #ffffff;
}

ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

li {
    list-style: none;
    padding: 0px;
    margin: 8px 0 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-feature-settings: "tnum" on, "lnum" on, "kern" off;
    color: rgba(255, 255, 255, 0.4);
}

.footer-group>ul>li>a {
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-feature-settings: "tnum" on, "lnum" on, "kern" off;
    color: rgba(255, 255, 255, 0.4);
}

.footer-group>ul>li>a:hover {
    color: #fff;
}

.social-media-links {
    max-width: 1148px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-bottom: 66px;
}

.social-media-links li {
    margin-right: 24px;
}

@media (max-width: 1200px) {
    
}
</style>