import connect from "./index";

const autoConnect = () => {
  const { state, connectWallet } = connect();
  if (state.status) {
    if (localStorage.getItem("walletconnect") == null) {
      state.status = false;
      state.address = "";
      localStorage.removeItem("userState");
    }
    if (localStorage.getItem("walletconnect")) {
      (async () => {
        connectWallet('walletconnect');
      })();
    } else {
        connectWallet('injected');
    }
  }
};
export default autoConnect;