<template>
    <nav class="flex items-center justify-between">
        <div class="flex">

            <router-link to="/"><img class="logo" src="~@/assets/imgs/logo.png" alt="" /></router-link>
            
            
        </div>
        <div class="flex flex-row items-center">
            <router-link class="vote" to="/artworks">Explore all artworks</router-link>
            <a class="vote" target="_blank" href="https://www.binance.com/en/nft/collection/liveartx-global-100-608842023285170176">Binance NFT</a>
        </div>

        <div class="flex md:space-x-4 items-center">
            <div class="h-6">
                <a href="https://discord.com/invite/liveartx" target="_blank" rel="noopener">
                    <img src="~@/assets/imgs/discord.svg" alt="" />
                </a>
            </div>

            <div class="h-6">
                <a href="https://twitter.com/liveartX" target="_blank" rel="noopener">
                    <img src="~@/assets/imgs/twitter.svg" alt="" />
                </a>
            </div>

            <ul class="user py-3 flex space-x-4 items-center">
                <li v-if="!state.address" @click="open"><button type="button" class="cursor-pointer">Connect
                        wallet</button>
                </li>
                <li v-if="state.address" class="flex space-x-2 items-center w-full">
                    <div ref="dropdownMenu" class="flex space-x-4">

                        <div class="flex space-x-2 items-center"  @click="showSubMenu = !showSubMenu">
                            
                            <button class="cursor-pointer w3-account"><span class="account">{{
                                    web3Account
                            }}</span></button>
                            <img src="~@/assets/imgs/avatar.png" height="40" width="40" alt="">
                        </div>
                            <ul class="dropdown w-42 sm:w-56 pt-4 pb-4" v-show="showSubMenu">
                            <li class="w-full group block cursor-pointer select-none list-none whitespace-nowrap px-3 py-2 h-12" @click="connectWallet">
                                     <div class="flex items-center space-x-2">
                                        <img class="ml-2 sm:ml-4" src="~@/assets/imgs/switch_wallet.svg" alt="">
                                        <p class="ml-1">Switch wallet</p>
                                    </div>
                                </li>
                                <li class="w-full group block cursor-pointer select-none list-none whitespace-nowrap px-3 py-2 h-12" @click="disconnectUserWallet">
                                    <div class="flex items-center space-x-2">
                                        <img class="ml-2 sm:ml-4" src="~@/assets/imgs/logout.svg" alt="">
                                        <p class="ml-1">Log out</p>
                                    </div>
                                </li>
                                
                            </ul>

                    </div>

                </li>
                <li>

                </li>
            </ul>
        </div>


    </nav>

    <BaseModal ref="walletConnectModal" class="base-modal">
        <template #header>
            <h3>
                {{ 'Connect your wallet' }}
            </h3>
        </template>
        <div>
            <p class="sub-title">Connect with one of the available providers</p>
            <div class="providers">
                <div class="wallets">
                    <div class="provider border-right cursor-pointer" @click="connectUserWallet('injected')">
                        <img src="~@/assets/imgs/metamask.svg"
                                alt="" />
                        <p class="wallet-name">MetaMask</p>
                        <p class="wallet-desc">Connect to your MetaMask Wallet</p>
                    </div>
                    <div class="provider cursor-pointer" @click="connectUserWallet('walletConnect')">
                        <img
                                src="~@/assets/imgs/walletconnect.svg" alt="" />
                        <p class="wallet-name">WalletConnect</p>
                        <p class="wallet-desc">Scan with WalletConnect to connect</p>
                    </div>

                </div>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import { ref } from 'vue';
import BaseModal from './baseModal.vue';
import connect from '@/composables/connect';
import { onMounted } from "vue";


export default {
    name: 'home-nav',
    data() {
        return {
            is_work_path: false,
            showSubMenu: false,
        }
    },
    components: {
        BaseModal,
    },
    setup() {
        const { autoConnect, connectWallet, disconnectWallet, state } = connect();
        const connectUserWallet = async (connector) => {
            await connectWallet(connector);
            close();
        };
        const disconnectUserWallet = async () => {
            await disconnectWallet();
        }
        const walletConnectModal = ref(null);

        const open = () => {
            walletConnectModal.value.open();
        }

        const close = () => {
            walletConnectModal.value.close();
        }
        onMounted(async () => {
            await autoConnect();
        })
        return {
            walletConnectModal, open, close, connectUserWallet, state, disconnectUserWallet
        }
    },
    methods: {
        hiddenSubMenu(e) {
            if (this.$refs.dropdownMenu && !this.$refs.dropdownMenu.contains(e.target)) {
                this.showSubMenu = false;
            }
        },

        async connectWallet() {
            await this.open();
            this.showSubMenu = false;
        }

    },
    computed: {
        web3Account() {
            const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
            const account = this.state.address;
            if (account) {
                const match = account.match(truncateRegex);
                if (!match) return account;
                return `${match[1]}…${match[2]}`;
            }
            return account;
        }
    },
    mounted() {
        document.addEventListener('click', this.hiddenSubMenu)
    },
    beforeUnmount() {
        document.removeEventListener('click', this.hiddenSubMenu)
    }


}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #000000;
}

p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

button {
    background-color: transparent;
    border: none;
    height: 46px;
    font-size: 18px;

}

.vote {
    display: block;
    /* width: 108px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'kern' off;
    color: #000000;
    padding: 8px;
    text-align: center;
    margin-right: 48px;
}

.vote:hover {
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 8px;

}
.dropdown {
    border: 1px solid #2d2d2d;
    /* border-radius: 5px; */
    overflow: hidden;
    inset: 70px 8px auto auto;
    position: absolute;
    /* transform: translateY(70px); */
    background: #FFFFFF;
    display: block;
    transition: all 0.5s ease;
    z-index: 20;
    /* width: 16em; */
    box-shadow: 0px 1px 32px rgba(32, 38, 53, 0.2);
    border-radius: 10px;
}

.dropdown>li:hover,
.dropdown>li:focus-within {
    background-color: rgba(0, 0, 0, 0.03);
}

li:focus-within a {
    outline: none;
}

.dropdown>li {
    float: left;
    position: relative;
    background: #FFFFFF;
    clear: both;
    opacity: 1;
    display: block;
    color: #000;
    visibility: visible;
}


h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
    margin-left: 45px;
    margin-bottom: 0;
}

.sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;
    /* width: 300px; */
    padding-left: 45px;
    margin-top: 8px;
}

.close {
    font-size: 42px;
    font-weight: 100;
    margin-left: auto;
    margin-right: 10px;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.base-modal {
    --bg-color: linear-gradient(153.06deg, #767B7E -0.31%, #000000 99.69%);
    --max-height: 446px;
    /* width: 340px;
    height: 446px;
    background: linear-gradient(153.06deg, #767B7E -0.31%, #000000 99.69%);
    background-blend-mode: luminosity;
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-shadow: 0px 1px 32px rgba(32, 38, 53, 0.2);
    border-radius: 10px; */
}

.hiding {
    display: none;
}


.social-media {
    margin-left: auto;
    width: 96px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 16px;
}

.logo {
    padding-left: 16px;
    width: 113px;
    /* height: 26px; */
}

.providers {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 54px;
}
.wallets {
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    height: 210px;
}

.wallet-name {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    font-feature-settings: 'kern' off;
    color: #000000;
}
.wallet-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    font-feature-settings: 'kern' off;
    color: rgba(124, 126, 131, 0.8);
}

.provider {
    margin-left: 45px;
    padding-right: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.border-right {
    border-right: #F5F5F5 solid 2px;
}

.provider img {
    width: 64px;
    height: 64px;
}

.button>img {
    margin-left: 24px;
    margin-right: 6px;
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-top: 16px;
    width: 300px;
    height: 56px;
    border-radius: 10px;
    font-family: "Hurme Geometric Sans 2";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-feature-settings: "tnum" on, "lnum" on, "kern" off;
    outline: none;
    transition: all 0.2s linear 0s;
    cursor: pointer;
    background: #ffffff;
    color: #000000;
    border: 1px solid #E5E5E5
}

.account {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: right;
    letter-spacing: 0.15px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'kern' off;

    /* Dark/100 */

    color: #000000;
    display: inline;
    /* margin-left: 1em; */
}

svg {
    margin-right: 1em;
}

@media (max-width: 768px) {

    .wallets {
        height: auto;
        flex-direction: column;
    }
    .provider {
        margin-bottom: 1em;
    }
    .border-right {
        border-right: none;
    }
    .w3-account {
        display: none;
    }
    .vote {
        width: auto;
        margin-right: 0;
    }
}
</style>