<template>
  <page-layout></page-layout>
</template>

<script>
import PageLayout from './components/layout.vue'
export default {
  name: 'App',
  components: {
    PageLayout,
  }
}
</script>

<style>
#app {}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  src: url("~@/assets/fonts/HurmeGeometricSans2-Regular.woff2");
}

@font-face {
  font-family: 'iconfont';
  src: url("~@/assets/fonts/icofont.woff2")format("woff2"),
  url("~@/assets/fonts/icofont.woff") format("woff");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;

}

body {
  width: 100%;
  height: 100%;
  font-family: "Hurme Geometric Sans 2";
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
</style>
