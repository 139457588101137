<template>
    <home-nav></home-nav>
    <router-view></router-view>
    <home-footer></home-footer>
</template>

<script>
import HomeNav from './nav.vue';
import HomeFooter from './footer.vue';

export default {
    name: 'PageLayout',
    components: { HomeNav, HomeFooter, },
    created() {
        window.addEventListener('onload', function () {
            window.scrollTo(0, 0);
        });
    }
}
</script>