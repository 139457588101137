<template>

    <div v-show="!hidden" class="modal">
        <div class="backdrop" @click="close" />
        <div class="shell relative">
            <div v-if="$slots.header" class="">
                <slot name="header" />
            </div>
            <div class="modal-body">
                <slot />
            </div>
            <div v-if="$slots.footer" class="border-t p-4 text-center">
                <slot name="footer" />
            </div>
            <a  class="absolute right-0 top-1" @click="close">
                <span class="close" id="close-modal" @click="close">&times;</span>
            </a>
        </div>
    </div>
</template>
<script>

export default {
    name: "RegModal",
    data() {
        return {
            hidden: true,
            checked: false,
            header: ""
        }
    },
    methods: {
        open(header) {
            this.checked = false;
            this.hidden = false;
            this.header = header
        },
        close() {
            this.hidden = true;
        }
    }
}

</script>
<style scoped>
p {
    margin: 0;
}

.hidden {
    display: none;
}

.modal {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  z-index: 40;
}
.backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.4);
  }

  .shell {
    background: #FFFFFF;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 790px;
    overflow-y: auto !important;
    max-height: var(--max-height);
    display: flex;
    flex-direction: column;
    z-index: 999;
    margin: 0 auto;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-shadow: 0px 1px 32px rgba(32, 38, 53, 0.2);
    border-radius: 10px;
  }
    .modal-body {
      max-height: 420px;
      flex: auto;
      text-align: initial;
      overflow-y: auto;
      overflow-x: hidden;
    }

@media (max-width: 767px)  {
    .shell {

        border: 0;
        width: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        min-height: 100% !important;
        margin-bottom: 0 !important;
    }
      .modal-body {
        max-height: 100% !important;
      }
}

.close {
    font-size: 42px;
    font-weight: 100;
    margin-left: auto;
    margin-right: 10px;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
</style>